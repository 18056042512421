import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//helper
import { getRegistrationRouteUrl } from "src/helper";

const ProtectedRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  const location = useLocation();

  if (!user.isAuthenticated) {
    return (
      <Navigate
        to={getRegistrationRouteUrl()}
        replace
        state={{ from: location }}
      />
    );
  }

  return children;
};

export default ProtectedRoute;
