import { API, URL } from "src/config/api";

export const uploadFile = async (data) => {
  try {
    const response = await API.post(URL.uploadFormFiles, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const singleFile = async (id, jwt) => {
  try {
    const response = await API.get(`${URL.uploadFormFiles}/${id}/${jwt}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteFile = async (id) => {
  try {
    const response = await API.delete(`${URL.uploadFormFiles}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const downloadFile = async (data) => {
  try {
    const response = await API.post(`${URL.uploadFormFiles}/download`, data);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
