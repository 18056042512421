export const colors = {
  white: "#ffffff",
  black: "#000000",
  primary: {
    50: "#dcf6ff",
    100: "#b0e0ff",
    200: "#80cbff",
    300: "#50b6fe",
    400: "#28a1fc",
    500: "#1788e3",
    600: "#0a6ab2",
    700: "#005087",
    800: "#002d4f",
    900: "#00101f",
    main: "#005087",
  },
  secondary: {
    50: "#fff9da",
    100: "#ffecad",
    200: "#ffdf7d",
    300: "#ffd24b",
    400: "#ffc51a",
    500: "#e6ab00",
    600: "#b38500",
    700: "#805f00",
    800: "#4e3900",
    900: "#1d1300",
    main: "#ffc51a",
  },
  navy: { dark: "#101c30", light: "#17243d" },
  sky: { dark: "#dae9f7", light: "#eff7ff" },
  red: { dark: "#a42d44", light: "#ca2241" },
  green: { dark: "#11534d", light: "#2f726b" },
  yellow: { dark: "#8a8328", light: "#cabb00" },
  blue: { dark: "#205aaa", light: "#3488fc" },
  orange: { dark: "#9d7627", light: "#ce9d14" },
  darkGray: { dark: "#34495E", light: "#40617e" },
  lightGray: { dark: "#BEC3C7", light: "#EBF1F1" },
};
