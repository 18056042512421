export const containerStyles = {
  components: {
    Container: {
      baseStyle: {
        maxW: {
          sm: "30em",
          md: "100%",
          lg: "95%",
          xl: "95%",
          "2xl": "94em",
        },
      },
    },
  },
};
