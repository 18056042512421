import { SET_APP_DATA } from "src/redux/constants";

const initialState = { loading: true };

export default function (state = initialState, action) {
  const payloadData = action.payload;
  switch (action.type) {
    case SET_APP_DATA:
      return { ...state, ...payloadData, loading: false };

    default:
      return state;
  }
}
