import { API, URL } from "src/config/api";

export const refreshToken = async (refreshToken) => {
  try {
    const response = await API.post(URL.auth.token.refresh, { refreshToken });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: err?.response?.status });
  }
};

export const getLoginUrl = async (id) => {
  try {
    const response = await API.get(`${URL.auth.token.generate}/${id}`);
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject({ err, status: err?.response?.status });
  }
};

export const loginDirectUser = async (id) => {
  try {
    const response = await API.get(`${URL.auth.token.validate}/${id}`);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject({
      message: error?.response?.data?.message || "مشکلی پیش آمده است",
      status: 401,
    });
  }
};
