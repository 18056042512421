const routes = [
  // PUBLIC ROUTES
  {
    directory: "root",
    url: "",
    private: false,
    index: true,
  },
  {
    directory: "registration",
    url: "login",
    private: false,
    index: false,
  },
  // PRIVATE ROUTES
  {
    directory: "dashboard",
    url: "dashboard",
    key: "dashboard",
    private: true,
    index: false,
    children: [
      {
        directory: "reports",
        url: "reports",
        key: "reports",
        priority: 15,
      },
      // {
      //   directory: "users",
      //   url: "users",
      //   key: "users",
      //   priority: 3,
      // },
      // {
      //   directory: "permissions",
      //   url: "permissions",
      //   key: "permissions",
      //   priority: 4,
      // },
      // {
      //   directory: "roles",
      //   url: "roles",
      //   key: "roles",
      //   priority: 5,
      // },
      // {
      //   directory: "config",
      //   url: "config",
      //   key: "config",
      //   priority: 12,
      // },
      // {
      //   directory: "logs",
      //   url: "logs",
      //   key: "logs",
      //   priority: 16,
      // },
    ],
  },
];

export default routes;
