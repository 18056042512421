import { UAParser } from "ua-parser-js";
import _ from "underscore";

const getDeviceInfo = async () => {
  const ua = new UAParser();

  let generalData = await ua.getResult().withClientHints();
  generalData = _.pick(generalData, ["browser", "device", "os"]);

  const deviceData = await ua.getDevice().withClientHints();

  const deviceWidth = window.innerWidth;
  const deviceHeight = window.innerHeight;

  return {
    ...generalData,
    isMobile: deviceData.is("mobile"),
    width: deviceWidth,
    height: deviceHeight,
  };
};

export default getDeviceInfo;
