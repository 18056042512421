import styled from "styled-components";
import Icon from "src/config/icon.js";
import { InputLeftElement, Heading } from "@chakra-ui/react";

// export const Header = styled(Heading)`
//   width: 100%;
//   text-align: center;
//   margin-bottom: 40px;
//   margin-top: 25px;
//   span {
//     color: #df1d2f;
//   }
// `;
