import jwt_decode from "jwt-decode";

//helper
import { setApiHeaders } from "src/helper";

//config
import setAuthToken from "src/config/api/setAuthToken";
import { API, URL } from "src/config/api";

//redux
import {
  SET_CURRENT_USER,
  LOG_OUT_USER,
  SET_USER_LOADING,
  SET_USER_DATA,
  SET_APP_DATA,
} from "src/redux/constants";

const authUrls = URL.auth;

export const setCurrentUser =
  (token = null, refreshToken = null) =>
  async (dispatch) => {
    localStorage.setItem("userToken", token);
    if (refreshToken) {
      localStorage.setItem("userRefreshToken", refreshToken);
    }

    setAuthToken(token);

    const decoded = jwt_decode(token);

    try {
      const response = await API.get(`${URL.users}/${decoded.id}`);
      const userInfo = response.data;

      setApiHeaders({
        path: response.config.headers.path || "/",
      });
      dispatch({
        type: SET_CURRENT_USER,
        payload: userInfo,
      });
      return Promise.resolve(userInfo);
    } catch (err) {
      console.log(err);
    }
  };

export const saveUser = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_CURRENT_USER,
      payload: {},
    });
    dispatch({
      type: SET_APP_DATA,
      payload: {},
    });
    return Promise.resolve({});
  } catch (error) {
    console.log(error);
  }
};

export const registerUser =
  (data, loginAfterRegister = true) =>
  async (dispatch) => {
    try {
      const response = await API.post(authUrls.credentials.register, data);
      if (loginAfterRegister) {
        response.then(() => dispatch(loginCredentials(data)));
      } else {
        return Promise.resolve(response);
      }
    } catch (err) {
      // dispatch({
      //   type: SET_USER_ERROR,
      //   payload: err.response,
      // });
      return Promise.reject(err.response.data);
    }
  };

export const updateUser = (data, id) => async (dispatch) => {
  try {
    const response = await API.put(`${URL.users}/${id}`, data);
    dispatch({
      type: SET_USER_DATA,
      payload: response.data.data,
    });
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err.response.data);
  }
};

export const loginCredentials = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_LOADING,
      payload: true,
    });
    const response = await API.post(authUrls.credentials.login, data);

    const { token, refreshToken } = response.data;
    await dispatch(setCurrentUser(token, refreshToken));
  } catch (errorObj) {
    const error = errorObj?.err;
    // dispatch({
    //   type: SET_USER_ERROR,
    //   payload: error?.response?.data,
    // });
    return Promise.reject({
      message: error?.response?.data?.message || "مشکلی پیش آمده است",
      status: 401,
    });
  }
};

export const sendOTP = (data, rayId) => async (dispatch) => {
  try {
    // dispatch({
    //   type: SET_USER_LOADING,
    //   payload: true,
    // });

    const response = await API.post(authUrls.otp.login, data, {
      headers: { ray: rayId },
    });

    return Promise.resolve(response.data);
  } catch (err) {
    // dispatch({
    //   type: SET_USER_ERROR,
    //   payload: err.response,
    // });
    return Promise.reject(err.response.data);
  }
};

export const verifyOTP = (data, uid) => async (dispatch) => {
  try {
    // dispatch({
    //   type: SET_USER_LOADING,
    //   payload: true,
    // });

    const response = await API.post(authUrls.otp.check, data, {
      headers: {
        id: uid,
      },
    });

    const { token, refreshToken } = response.data;

    await dispatch(setCurrentUser(token, refreshToken));
  } catch (err) {
    // dispatch({
    //   type: SET_USER_ERROR,
    //   payload: err.response,
    // });
    return Promise.reject(err.response.data);
  }
};

export const logoutUser = () => (dispatch) => {
  // dispatch({
  //   type: SET_APP_LOADING,
  //   payload: true,
  // });
  localStorage.removeItem("userToken");
  localStorage.removeItem("userModelKeys");
  localStorage.removeItem("userRefreshToken");
  setAuthToken(false);
  dispatch({
    type: LOG_OUT_USER,
  });
  // dispatch({
  //   type: SET_APP_LOADING,
  //   payload: false,
  // });
};

export const loginSSO = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_LOADING,
      payload: true,
    });
    const response = await API.post(authUrls.sso.login, data);
    const { token, refreshToken } = response.data;
    await dispatch(setCurrentUser(token, refreshToken));
  } catch (errorObj) {
    const error = errorObj?.err;
    // dispatch({
    //   type: SET_USER_ERROR,
    //   payload: error?.response?.data,
    // });
    return Promise.reject({
      message: error?.response?.data?.message || "مشکلی پیش آمده است",
      status: 401,
    });
  }
};
