import { mode } from "@chakra-ui/theme-tools";

const disabledStyles = (props) => {
  return {
    _disabled: {
      bg: mode("darkGray.dark", "darkGray.light")(props),
    },
  };
};
const primaryStyles = (props) => {
  return {
    color: "white",
    // bg: mode("blue.light", "blue.dark")(props),
    bg: "#075E54",
    ...disabledStyles(props),
    _hover: {
      // bg: mode("primary.600", "primary.500")(props),
      bg: "#067b6e",
      color: "white",
      ...disabledStyles(props),
    },
  };
};
const outlineStyles = (props) => {
  return {
    color: "white",
    bg: "transparent",
    color: mode("blue.light", "blue.dark")(props),
    // bg: mode("blue.light", "blue.dark")(props),
    border: "1px solid",
    borderColor: mode("blue.light", "blue.dark")(props),
    ...disabledStyles(props),
    _hover: {
      bg: mode("primary.600", "primary.500")(props),
      color: "white",
      ...disabledStyles(props),
    },
  };
};

export const buttonStyles = {
  components: {
    Button: {
      defaultProps: {
        size: "sm",
      },
      baseStyle: {
        w: "100%",
        borderRadius: "2xl",
      },
      variants: {
        primary: (props) => ({
          border: "none",
          h: "60px",
          ...primaryStyles(props),
        }),
        outline: (props) => ({
          border: "none",
          h: "55px",
          ...outlineStyles(props),
        }),
        prevStep: (props) => ({
          border: "none",
          h: "45px",
          w: "100px",
          ...primaryStyles(props),
        }),
        rejectStepOutline: (props) => ({
          border: "none",
          w: "auto",
          h: "55px",
          ...primaryStyles(props),
          border: "2px solid",
          bordeColor: mode("red.light", "red.dark")(props),
          bg: "transparent",
          color: mode("red.light", "red.dark")(props),
          _hover: {
            bg: mode("red.dark", "red.light")(props),
            color: "white",
          },
        }),
        rejectStep: (props) => ({
          border: "none",
          w: "auto",
          h: "55px",
          ...primaryStyles(props),
          bg: mode("red.light", "red.dark")(props),
          _hover: {
            bg: mode("red.dark", "red.light")(props),
            color: "white",
          },
        }),
        nextStep: (props) => ({
          border: "none",
          w: "auto",
          h: "55px",
          ...primaryStyles(props),
        }),
        cancel: (props) => ({
          color: "white",
          bg: mode("red.light", "red.dark")(props),
          border: "none",
          h: "55px",
          ...disabledStyles(props),
          _hover: {
            bg: mode("red.dark", "red.light")(props),
            color: "white",
          },
        }),
        fab: (props) => ({
          position: "absolute",
          border: "none",
          bottom: "15px",
          right: "15px",
          w: "50px",
          h: "50px",
          borderRadius: "full",
          color: "white",
          bg: mode("blue.light", "blue.dark")(props),
          ...disabledStyles(props),
          _hover: {
            bg: mode("primary.600", "primary.500")(props),
            color: "white",
            ...disabledStyles(props),
          },
        }),
        table: (props) => ({
          h: "45px",
          borderRadius: "xl",
          padding: "0px 25px",
          fontSize: "sm",
          ...primaryStyles(props),
        }),
        iconButton: (props) => ({
          bg: "transparent",
          border: "none",
          w: "auto",
          h: "auto",
          _hover: { bg: "transparent" },
          _focus: { bg: "transparent" },
          _active: { bg: "transparent" },
        }),
        navbarItems: (props) => ({
          h: "50px",
          borderRadius: "none",
          justifyContent: "flex-start",
          color:
            props.active === "active"
              ? mode("white", "white")(props)
              : mode("black", "white")(props),
          fontSize: "sm",
          padding: "0px 20px",
          bg:
            props.active === "active"
              ? mode("blue.light", "blue.dark")(props)
              : "transparent",
          _hover: {
            bg:
              props.active === "active"
                ? mode("blue.light", "blue.dark")(props)
                : mode("sky.dark", "navy.light")(props),
            borderRadius: "none",
          },
        }),
        subNavbarItems: (props) => ({
          h: "50px",
          borderRadius: "none",
          justifyContent: "flex-start",
          color:
            props.active === "active"
              ? mode("white", "white")(props)
              : mode("black", "white")(props),
          fontSize: "sm",
          padding: "0px 20px",
          bg:
            props.active === "active"
              ? mode("blue.light", "blue.dark")(props)
              : mode("sky.dark", "navy.light")(props),
          _hover: {
            bg:
              props.active === "active"
                ? mode("blue.light", "blue.dark")(props)
                : mode("sky.light", "navy.dark")(props),
            borderRadius: "none",
          },
        }),
      },
    },
  },
};
