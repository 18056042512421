const url = {
  auth: {
    otp: {
      captcha: "auth/otp/captcha",
      login: "auth/otp/login",
      check: "auth/otp/checkotp",
      again: "auth/otp/sendagain",
    },
    credentials: {
      login: "auth/credentials/login",
      register: "auth/credentials/register",
    },
    sso: {
      generate: "auth/sso/generate",
      login: "auth/sso/login",
    },
    token: {
      refresh: "auth/token/refresh",
      generate: "auth/token/generate",
      validate: "auth/token/validate",
    },
    logout: "auth/logout",
  },
  shahkar: "shahkar",
  sms: "sms",
  users: "users",
  usersInfo: "users-infos",
  personelInfo: "personel-infos",
  wifeInfo: "wife-infos",
  childrenInfo: "children-infos",
  permissions: "permissions",
  exportTemplates: "export-templates",
  batchActions: "batch/actions",
  roles: "roles",
  offices: "offices",
  subOffices: "offices/sub-office",
  proccesses: "proccesses",
  requests: "requests",
  uploads: "uploads",
  conditions: "conditions",
  uploadFormFiles: "files",
  forms: "forms",
  reports: "reports",
  submitted: "submitted-form",
  nonStepForms: "forms/non-step",
  steps: "steps",
  curl: "curl",
  loggers: "loggers",
  instalments: "instalments",
  transactions: "transactions",
  parameters: "parameters",
  customlists: "customlists",
  config: "configs",
  viewcount: "viewcounts",
  base: "base",
  bank: "https://payment.sbbiran.ir/api",
};

export default url;
