import axios from "axios";

//config
import config from "src/config";
import url from "src/config/api/url";
import getDeviceInfo from "src/config/device-info";

//services
import { refreshToken } from "src/services/api/token";

export const URL = url;
export const API = axios.create({
  baseURL: config.axiosBaseUrl,
});

let isRefreshing = false;
let refreshQueue = [];

const processQueue = (error, token = null) => {
  refreshQueue.forEach((prom, idx) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
    refreshQueue.splice(idx, 1);
  });
};

const redirectToRoot = () => {
  localStorage.clear();
  localStorage.setItem("appVersion", config?.version);
  window.location.href = "/";
};

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("userToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Handle deviceInfo data
    const deviceInfo = await getDeviceInfo();
    config.headers["device-info"] = JSON.stringify(deviceInfo);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // console.log("INTERCEPTOR");
    const originalRequest = error.config;

    // If the error is due to an expired token, try to refresh the token
    if (error?.response?.status === 401) {
      // console.log("UN-AUTH");
      if (error?.response?.data?.logout) {
        // console.log("REDIRECT");
        redirectToRoot();
      }

      if (!isRefreshing) {
        isRefreshing = true;
        // console.log("CHANGE IS_REFRESHING");

        try {
          // console.log("GET FOR HANDLE REFRESH_TOKEN");
          const refreshTokenValue = localStorage.getItem("userRefreshToken");
          const { token, refresh } = await refreshToken(refreshTokenValue);
          localStorage.setItem("userToken", token);
          localStorage.setItem("userRefreshToken", refresh);

          isRefreshing = false;
          processQueue(null, token);

          // Update the default headers with the new token
          API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          originalRequest.headers.Authorization = `Bearer ${token}`;

          // console.log("GET FOR HANDLE REFRESH_TOKEN IS DONE");
          return API(originalRequest);
        } catch (refreshError) {
          // console.log("GET FOR HANDLE REFRESH_TOKEN __ ERROR");
          isRefreshing = false;
          processQueue(refreshError);

          return Promise.reject(refreshError);
        }
      } else {
        // console.log("IS_REFRESHING ...");
        // If refresh is already in progress, add this request to the queue
        return new Promise((resolve, reject) => {
          refreshQueue.push({ resolve, reject });
        })
          .then((token) => {
            // console.log("IS_REFRESHING IS DONE");
            // Update the default headers with the new token
            API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            originalRequest.headers.Authorization = `Bearer ${token}`;

            return API(originalRequest);
          })
          .catch((error) => redirectToRoot());
      }
    }

    return Promise.reject(error);
  }
);
