import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser, saveUser } from "src/redux/user/actions";
import { getAppData } from "src/redux/app/actions";

//pages
import routes from "./routes";
import ProtectedRoute from "src/routes/protected-route";

//components
import Loader from "src/components/loader";
import ErrorPage from "src/components/404-error";

//hooks
import useChange from "src/hooks/useChange";

//loadable
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//helper
import { setApiHeaders } from "src/helper";

//css
import "react-day-picker/dist/style.css";

const LoadablePage = loadable(
  ({ directory, path }) => {
    setApiHeaders({
      path,
    });

    // `src/pages/${directory}`
    return pMinDelay(import(`src/pages/${directory}`), 0);
  },
  {
    cacheKey: (props) => props.directory,
  }
);

const getIndexRoute = routes.filter((route) => route.index === true);
export const indexRoute = getIndexRoute ? getIndexRoute[0] : routes[0];

const App = () => {
  //redux
  const dispatch = useDispatch();
  const { app, user } = useSelector((state) => ({
    app: state.app,
    user: state.user,
  }));

  //states
  const [router, setRouter] = useState(null);

  useEffect(() => {
    // ** STARTED **
    getAuth();
  }, []);

  useChange(() => {
    if (!app.loading) {
      prepareRouteConfig();
    }
  }, [app.loading]);

  const getAuth = async () => {
    // _____ AUTH _____
    // GET AUTH FOR USER
    const token = localStorage.getItem("userToken");
    if (token) {
      // console.log("AUTH TRUE");
      dispatch(saveUser());
      // AUTH TOKEN IS EXIST
      // try {
      //   // AUTHING USER...
      //   setApiHeaders({ path: "/" });
      //   // USER AUTH SUCCESSFULLY
      //   await dispatch(setCurrentUser(token));
      //   // _____ DATA _____
      //   dispatch(getAppData());
      //   // DATA FETCHED SUCCESSFULLY
      // } catch (error) {
      //   // USER AUTH FAILED
      // }
    } else {
      // console.log("AUTH FALSE");
      // APP TOKEN IS NOT FOUND
      // _____ DATA _____
      // NOTHING NEED TO FETCH
      prepareRouteConfig();
    }
  };

  const prepareRouteConfig = () => {
    // console.log("ROUTE");
    // _____ ROUTES _____
    // GET FOR GENERATE ROUTES
    let routeConfig = [...routes];
    if (!user.isAuthenticated) {
      routeConfig = routeConfig.filter(
        (route) => route.private === user.isAuthenticated
      );
    }
    const routesList = generateRoutes(routeConfig);
    // console.log(routesList);
    setRouter(createBrowserRouter(routesList));
    // ALL ROUTES GENERATED AND IS: , routesList
  };

  const generateRoutes = (routesArray, parent = null) => {
    const allRoutes = [];

    routesArray.map((route) => {
      const nestedRoute = route.hasOwnProperty("children");
      if (nestedRoute) {
        let childRoutes = route.children;
        // if (route.key === "dashboard") {
        //   childRoutes = childRoutes.filter(({ key }) =>
        //     user?.data?.role?.permissions
        //       .map(({ value }) => value)
        //       .includes(key)
        //   );
        // }
        const routeConfig = generateRoutes(childRoutes, route);
        allRoutes.push(...routeConfig);
      } else {
        const routeConfig = makeRouteConfig(route, parent || false);
        allRoutes.push(routeConfig);
      }
    });

    return allRoutes;
  };

  const makeRouteConfig = (route, parent) => {
    let routeObj = {};

    let path = `/${route.url}`;
    let directory = route.directory;

    if (parent) {
      path = `/${parent.url}/${route.url}`;
      if (route.hasOwnProperty("params")) {
        path = `/${parent.url}/${route.params}`;
      }
      directory = `${parent.directory}/pages/${route.directory}`;
      routeObj = {
        path,
        element: createRoute(path, parent.directory, parent.private),
        children: [
          {
            path,
            element: createRoute(path, directory, parent.private),
          },
        ],
        errorElement: <ErrorPage />,
      };
    } else {
      if (route.hasOwnProperty("params")) {
        path = path + `/${route.params}`;
      }
      routeObj = {
        path,
        element: createRoute(path, directory, route.private),
        errorElement: <ErrorPage />,
      };
    }

    return routeObj;
  };

  const createRoute = (path, directory, isPrivate) => {
    let routeElement;
    if (isPrivate) {
      routeElement = (
        <ProtectedRoute>
          <LoadablePage
            path={path}
            fallback={<Loader />}
            directory={directory}
          />
        </ProtectedRoute>
      );
    } else {
      routeElement = (
        <LoadablePage path={path} fallback={<Loader />} directory={directory} />
      );
    }
    return routeElement;
  };

  return !router ? <Loader /> : <RouterProvider router={router} />;
};

export default App;
