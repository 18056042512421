import { SET_APP_DATA } from "src/redux/constants";

//services
import { getData as getPermissions } from "src/services/api/permissions";
import { getData as getRoles } from "src/services/api/roles";

export const getAppData = () => async (dispatch) => {
  let appData = {};

  try {
    //permissions
    const permissionRes = await getPermissions();
    appData.permissions = permissionRes.data;

    //roles
    const roleRes = await getRoles();
    appData.role = roleRes.data;
  } catch (error) {}

  dispatch({
    type: SET_APP_DATA,
    payload: appData,
  });
};
