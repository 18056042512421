import React from "react";
import { Flex, Container, Heading, Image, Button } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { IMAGES } from "src/images";

const ErrorPage = () => {
  return (
    <Container>
      <Flex
        flex={1}
        h="100vh"
        justify="center"
        align={"center"}
        flexDir="column"
      >
        <Image w="400px" mb="70px" src={IMAGES.empty} />
        <Heading mb="20px">صفحه مورد نظر یافت نشد!</Heading>
        <NavLink to="/">
          <Button variant={"primary"} w="200px" h="50px">
            بازگشت به صفحه اصلی
          </Button>
        </NavLink>
      </Flex>
    </Container>
  );
};
export default ErrorPage;
